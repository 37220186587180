import Component from './Component';

const itemsInViewport = new Map();

class Observer {
  #callbacks = new Map();
  #observer;
  #handleIntersection = this.#onIntersection.bind(this);

  constructor(options) {
    this.#observer = new IntersectionObserver(this.#handleIntersection, options);
  }

  #onIntersection(entries) {
    entries.forEach(entry =>
      // Run callback
      this.#callbacks.get(entry.target)(
        entry.target,
        entry.isIntersecting,
        () => this.unobserve(entry.target)
      )
    );
  }

  observe(element, callback) {
    this.#callbacks.set(element, callback);
    this.#observer.observe(element);

    return () => this.unobserve(element);
  }

  unobserve(element) {
    this.#observer.unobserve(element);
    this.#callbacks.delete(element);
  }

  destroy() {
    this.#observer.disconnect();
  }
}

class Viewport extends Component {
  #observerInView = new Observer({
    threshold: 0
  });

  #observerInCenter = new Observer({
    rootMargin: `${-100 * (1 - 0.5)}% 0px ${-100 * 0.5}%`
  });

  constructor() {
    super();
  }

  inCenter(element, callback) {
    return this.#observerInCenter.observe(element, callback);
  }

  inViewport(element, callback) {
    return this.#observerInView.observe(element, callback);
  }

  onDestroy() {
    this.#observerInView.destroy();
    this.#observerInCenter.destroy();
  }
}

export default new Viewport();
