import Element from '../ANTICore/Element';

export default class PageNavigation extends Element {
  static EVENT_CHANGE = "navigation_change";

  #items;
  #sections;
  #currentItem;
  #onIntersectionHandler = this.onIntersection.bind(this);
  #observer = new IntersectionObserver(this.#onIntersectionHandler, {
    rootMargin: `${-100 * (1 - 0.5)}% 0px ${-100 * 0.5}%`,
  });

  constructor(element) {
    super(element);
    this.#items = [...this.element.children];
    this.#sections = [...document.querySelectorAll(this.#items.map(e => `#${e.dataset.target}`).join(','))];

    // TODO: maybe do some error handling
    // if (!pageNav || !pageSections.length) {
    //   return;
    // }

    this.#sections.forEach(el => this.#observer.observe(el));
  }


  onIntersection(entries) {
    entries.some((entry) => {
      if(entry.isIntersecting) {
        if(this.#currentItem) {
          this.#currentItem.classList.remove('is-current');
        }

        const index = this.#sections.indexOf(entry.target);
        this.#currentItem = this.#items[index];
        this.#currentItem.classList.add('is-current');

        this.events.emit(PageNavigation.EVENT_CHANGE, {
          slug: this.#currentItem.dataset.target,
          title: this.#currentItem.dataset.title || ''
        });

        return true;
      }

      return false;
    });
  }
}
