import { includes } from './util/String.js';

export const agent = navigator.userAgent.toLowerCase();
export const detectAgent = (match) => includes(agent, match);
export const isTouchCapable = !!navigator.maxTouchPoints;
export const pixelRatio = window.devicePixelRatio;

export const isRetina = window.devicePixelRatio > 1;
export const supportsWebWorker = !!window.Worker;
export const supportsGeoLocation = !!navigator.geolocation;
export const supportsPushState = !!window.history.pushState;
export const supportsWebcam = !!(navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.mediaDevices);
export const systemLanguage = window.navigator.userLanguage || window.navigator.language;
export const supportsWebAudio = !!window.AudioContext;

const xr = {
   xr: false,
   ar: false
};

export async function detectXR() {
    if(navigator.xr) {
        [
            xr.vr,
            xr.ar
        ] = await Promise.all([
            navigator.xr.isSessionSupported('immersive-vr'),
            navigator.xr.isSessionSupported('immersive-ar')
        ]);

        if(systemOS == 'android' && !detectAgent('oculus')) {
            xr.vr = false;
        }
    }

    return xr;
};

export const supportsLocalStorage = (() => {
    try {
      localStorage.setItem('ANTI_core', 'ANTI_core');
      localStorage.removeItem('ANTI_core');
      return true;
    } catch (e) {
      return false;
    }
})();

export const supportsFullscreen = document.fullscreenEnabled || document.webkitFullscreenEnabled || document.mozFullScreenEnabled || document.msFullscreenEnabled;

export const systemOS = function() {
    if(detectAgent(['ipad', 'iphone', 'ios'])
        || detectAgent(['mac'])
        && isTouchCapable
        && Math.max(screen.width, screen.height) < 1370
    ) {
        return 'ios';
    }

    if(detectAgent(['android', 'kindle'])) {
        return 'android';
    }

    if(detectAgent(['blackberry'])) {
         return 'blackberry';
    }

    if(detectAgent(['mac os'])) {
        return 'mac';
    }

    if(detectAgent(['windows', 'iemobile'])) {
        return 'windows';
    }

    if(detectAgent(['linux'])) {
        return 'linux';
    }

    return 'unknown';
}();

export const systemVersion = function() {
    try {
        if ('ios' == systemOS) {
            if (includes(agent, 'intel mac')) {
                let split = agent.split('version/')[1].split(' ')[0].split('.');

                return Number(split[0] + '.' + split[1]);
            }

            const num = agent.split('os ')[1].split('_');
            const main = num[0];
            const sub = num[1].split(' ')[0];

            return Number(main + '.' + sub);
        }

        if ('android' == systemOS) {
            let version = agent.split('android ')[1].split(';')[0];

            if(version.length > 3) {
                version = version.slice(0, -2);
            }

            if('.' == version.charAt(version.length - 1)) {
                version = version.slice(0, -1);
            }

            return Number(version);
        }

        if ('windows' == systemOS) {
            if(includes(agent, 'rv:11')) {
                return 11;
            }

            return Number(agent.split('windows phone ')[1].split(';')[0]);
        }
    } catch (e) {};

    return -1;
}();

export const systemBrowser = function() {
    if ('ios' == systemOS) {
        if(detectAgent(["twitter", "fbios", "instagram"])) {
            return "social";
        }

        if(detectAgent(['crios'])) {
            return "chrome";
        }

        if(detectAgent(['safari'])) {
            return "safari";
        }

        return 'unknown';
    }

    if('android' == systemOS) {
        if(detectAgent(["twitter", "fb", "facebook", "instagram"])) {
            return "social";
        }
        if(detectAgent(['chrome'])) {
            return "chrome";
        }
        if(detectAgent(['firefox'])) {
            return "firefox";
        }

        return 'browser';
    }

    if(detectAgent(["msie"]) || detectAgent(["trident"])
        && detectAgent(["rv:"]) || detectAgent(["windows"])
        && detectAgent(["edge"])
    ) {
        return 'ie';
    }

    if(detectAgent(['chrome'])) {
        return 'chrome';
    }

    if(detectAgent(['safari'])) {
        return 'safari';
    }

    if(detectAgent(['firefox'])) {
        return 'firefox';
    }

    return 'unknown';
}();

export const systemBrowserVersion = function() {
    try {
        if ("chrome" == systemBrowser) {
            return Number(agent.split("chrome/")[1].split(".")[0]);
        }

        if ("firefox" == systemBrowser) {
            return Number(agent.split("firefox/")[1].split(".")[0]);
        }

        if ("safari" == systemBrowser) {
            return Number(agent.split("version/")[1].split(".")[0].split(".")[0]);
        }

        if ("ie" == systemBrowser) {
            if (detectAgent(["msie"])) {
                return Number(agent.split("msie ")[1].split(".")[0]);
            }

            if (detectAgent(["rv:"])) {
                return  Number(agent.split("rv:")[1].split(".")[0]);
            }

            return Number(agent.split("edge/")[1].split(".")[0]);
        }

    } catch (e) {
        return -1
    }
}();

export const socialBrowser = function() {
    if(detectAgent(['instagram'])) {
        return 'instagram';
    }

    if(detectAgent(['fban', 'fbav', 'fbios'])) {
        return 'facebook';
    }

    if(detectAgent(['twitter']) || !(!document.referrer || !document.referrer.includes("//t.co/"))) {
        return 'twitter';
    }

    return false;
}();

export const isMobile =  function() {
    let mobile = false;

    if(!(!("ontouchstart" in window) && !("onpointerdown" in window) || !includes(systemOS, ['ios', 'android', 'magicleap']))) {
        mobile = true;
    }

    if(detectAgent('quest')) {
        mobile = true;
    }

    if(mobile && detectAgent(['windows']) && !detectAgent(['touch'])) {
        mobile = false;
    }


    return mobile;
}();

export const isTablet = isMobile && Math.max(window.screen ? screen.width : window.innerWidth, window.screen ? screen.height : window.innerHeight) > 1000;
export const isPhone = isMobile && !isTablet;
export const isPWA = isMobile && !(!window.matchMedia || !window.matchMedia("(display-mode: standalone)").matches) || !!window.navigator.standalone;

let _webglContext;
let _webglDisabled;
export const graphics = {
    get webgl() {
        if (_webglDisabled) {
            return false;
        }

        if (_webglContext) {
            return _webglContext;
        }

        try {
            const names = ["webgl2", "webgl", "experimental-webgl"];
            const canvas = document.createElement("canvas");

            let gl;
            for (let i = 0; i < names.length && !gl; i++) {
                gl = canvas.getContext(names[i]);
            }

            let info = gl.getExtension("WEBGL_debug_renderer_info");
            let output = {};

            if (info) {
                let gpu = info.UNMASKED_RENDERER_WEBGL;
                output.gpu = gl.getParameter(gpu).toLowerCase()
            } else {
                output.gpu = "unknown";
            }

            output.renderer = gl.getParameter(gl.RENDERER).toLowerCase();
            output.version = gl.getParameter(gl.VERSION).toLowerCase();
            output.glsl = gl.getParameter(gl.SHADING_LANGUAGE_VERSION).toLowerCase();
            output.extensions = gl.getSupportedExtensions();
            output.webgl2 = includes(output.version, ["webgl 2", "webgl2"]);
            output.canvas = canvas;
            output.context = gl;
            output.detect = function(matches) {
                if (output.gpu && includes(output.gpu.toLowerCase(), matches)) {
                    return true;
                }

                if (output.version && includes(output.version.toLowerCase(), matches)) {
                    return true;
                }

                for (let i = 0; i < output.extensions.length; i++) {
                    if (includes(output.extensions[i].toLowerCase(), matches)) {
                        return true;
                    }
                }

                return false;
            };

            if(!output.webgl2 || !output.detect("instance")) {
                _webglDisabled = true;
            }

            _webglContext = output;

            return output;
        } catch (e) {
            return false;
        }
    },

    set webgl(value) {
        if(false === value) {
            _webglDisabled = true;
        }
    },

    canvas: !!document.createElement('canvas').getContext
};
