export function sign(x) {
  x = +x;

  if (x === 0 || isNaN(x)) {
    return Number(x);
  }

  return x > 0 ? 1 : -1;
};

export function round(value, precision = 0) {
  let p = Math.pow(10, precision);

  return Math.round(value * p) / p;
};


export function random(min, max, precision = 0) {
  if (typeof min === 'undefined') {
    return Math.random();
  }

  if (min === max) {
    return min;
  }

  min = min || 0;
  max = max || 1;

  return round((min + Math.random() * (max - min)), precision);
};

export function degrees(radians) {
  return radians * (180 / Math.PI);
};

export function radians(degrees) {
  return degrees * (Math.PI / 180);
};


export function clamp(value, min = 0, max = 1) {
  return Math.min(Math.max(value, Math.min(min, max)), Math.max(min, max));
};


export function map(value, oldMin = -1, oldMax = 1, newMin = 0, newMax = 1, isClamp) {
  const newValue = (((value - oldMin) * (newMax - newMin)) / (oldMax - oldMin)) + newMin;

  if (isClamp) {
    return clamp(newValue, Math.min(newMin, newMax), Math.max(newMin, newMax));
  }

  return newValue;
};


export function mix(a, b, alpha) {
  return a * (1.0 - alpha) + b * alpha;
};


export function step(edge, value) {
  return (value < edge) ? 0 : 1;
};


export function smoothStep(min, max, value) {
  const x = Math.max(0, Math.min(1, (value - min) / (max - min)));

  return x * x * (3 - 2 * x);
};


export function fract(value) {
  return value - Math.floor(value);
};


export function mod(value, n) {
  return ((value % n) + n) % n;
};
