import ANTI_core from '../index.js';
import Events from '../Events.js';
import Render from '../Render.js';
import ObjectPool from '../util/ObjectPool.js';

function isIOS() {
  // Source:
  // https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
  return [
    'iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone',
    'iPod'
  ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
}

class Vec2 {
  x = 0;
  y = 0;

  length() {
    return Math.sqrt(this.x * this.x + this.y * this.y);
  }
}

const _objects = [];
const _events = {
  start: [],
  move: [],
  end: [],
  leave: []
};

export default class Interaction extends Events {
  static SUPPORTS_POINTER_EVENTS = Boolean(window.PointerEvent) &&
    // #setPointerCapture is buggy on iOS, so we can't use pointer events
    // until the following bug is fixed:
    // https://bugs.webkit.org/show_bug.cgi?id=220196
    !isIOS();

  //
  static EVENT_CLICK = "interaction_click";
  static EVENT_START = "interaction_start";
  static EVENT_MOVE = "interaction_move";
  static EVENT_DRAG = "interaction_drag";
  static EVENT_END = "interaction_end";

  static bind(evt, callback) {
    _events[evt].push(callback);
  }

  static unbind(evt, callback) {
    _events[evt].remove(callback);
  }

  static bindObject(obj) {
    _objects.push(obj);
  }

  static unbindObject(obj) {
    _objects.remove(obj);
  }

  #startListener = this.#onStart.bind(this);
  #moveListener = this.#onMove.bind(this);
  #endListener = this.#onEnd.bind(this);
  #leaveListener = this.#onLeave.bind(this);
  #tickListener = this.#onTick.bind(this);

  x = 0;
  y = 0;
  hold = new Vec2;
  last = new Vec2;
  delta = new Vec2;
  move = new Vec2;
  velocity = new Vec2;
  ignoreLeave = false;
  unlocked = false;

  #object;
  #touchId;
  #velocity = [];
  #moved = 0;
  #time = performance.now();

  #vec2Pool = new ObjectPool(Vec2, 10);
  #distance;
  #timeDown;
  #timeMove;
  #isTouching = false;

  constructor(object) {
    super();
    this.#object = object;

    this.#addHandlers();
    Render.start(this.#tickListener);
  }

  #addHandlers() {
    if(this.#object == window) {
      Interaction.bind('start', this.#startListener);
    } else {
      if (Interaction.SUPPORTS_POINTER_EVENTS) {
        // If supported, use pointer events API with #setPointerCapture.
        this.#object.addEventListener('pointerdown', this.#startListener, { capture: true, passive: false });
      } else {
        this.#object.addEventListener('touchstart', this.#startListener, { capture: true, passive: false });
        this.#object.addEventListener('mousedown', this.#startListener, { capture: true, passive: false });
      }
    }

    // Use global events for the rest
    Interaction.bindObject(this.#object);
    Interaction.bind("move", this.#moveListener);
    Interaction.bind("end", this.#endListener);
    Interaction.bind("leave", this.#leaveListener);
  }

  onDestroy() {
    if(this.#object == window) {
      Interaction.unbind('start', this.#startListener);
    } else {
      if (Interaction.SUPPORTS_POINTER_EVENTS) {
        // If supported, use pointer events API with #setPointerCapture.
        this.#object.removeEventListener('pointerdown', this.#startListener);
      } else {
        this.#object.removeEventListener('touchstart', this.#startListener, { capture: true, passive: false });
        this.#object.removeEventListener('mousedown', this.#startListener, { capture: true, passive: false });
      }
    }

    Interaction.unbind("move", this.#moveListener);
    Interaction.unbind("end", this.#endListener);
    Interaction.unbind("leave", this.#leaveListener);
    Render.stop(this.#tickListener);

    Interaction.unbindObject(this.#object);
  }

  #onStart(event) {
    if(event.type !== 'touchstart') {
      event.preventDefault();
    }

    if (this.#isTouching) {
      return;
    }

    this.#isTouching = true;

    let x = event instanceof MouseEvent ? event.clientX : event.targetTouches[0].clientX;
    let y = event instanceof MouseEvent ? event.clientY : event.targetTouches[0].clientY;

    if(event.changedTouches) {
      x = event.changedTouches[0].clientX;
      y = event.changedTouches[0].clientY;
      this.#touchId = event.changedTouches[0].identifier;
    }

    if(event.touches && "number" == typeof event.touches[0].force) {
      event.force = event.touches[0].force;
    }

    this.x = x;
    this.y = y;
    this.hold.x = this.last.x = x;
    this.hold.y = this.last.y = y;
    this.delta.x = this.move.x = this.velocity.x = 0;
    this.delta.y = this.move.y = this.velocity.y = 0;
    this.#distance = 0;
    this.events.emit(Interaction.EVENT_START, event, true);
    this.#timeDown = this.#timeMove = Render.TIME;
  }

  #onMove(event) {
    if (!this.#isTouching && !this.unlocked) {
      return;
    }

    let now = performance.now();

    if (now - this.#time < 16) {
      return;
    }

    this.#time = now;

    let x = event.x;
    let y = event.y;

    if (event.touches) {
      for (let i = 0; i < event.touches.length; i++) {
        let touch = event.touches[i];

        if(touch.identifier == this.#touchId) {
          x = touch.clientX,
          y = touch.clientY
        }
      }
    }

    if(this.#isTouching) {
      this.move.x = x - this.hold.x;
      this.move.y = y - this.hold.y;
    }

    if(event.touches && "number" == typeof event.touches[0].force) {
      event.force = event.touches[0].force;
    }

    this.x = x;
    this.y = y;
    this.delta.x = x - this.last.x;
    this.delta.y = y - this.last.y;
    this.last.x = x;
    this.last.y = y;

    this.#moved = 0;
    this.#distance += this.delta.length();

    let delta = Render.TIME - (this.#timeMove || Render.TIME);
    this.#timeMove = Render.TIME;
    if (delta > .01) {
      let velocity = this.#vec2Pool.get();
      velocity.x = Math.abs(this.delta.x) / delta;
      velocity.y = Math.abs(this.delta.y) / delta;
      this.#velocity.push(velocity);

      if(this.#velocity.length > 5) {
        this.#vec2Pool.put(this.#velocity.shift());
      }
    }

    this.velocity.x = this.velocity.y = 0;

    for (let i = 0; i < this.#velocity.length; i++) {
      this.velocity.x += this.#velocity[i].x;
      this.velocity.y += this.#velocity[i].y;
    }

    this.velocity.x /= this.#velocity.length;
    this.velocity.y /= this.#velocity.length;

    this.velocity.x = this.velocity.x || 0;
    this.velocity.y = this.velocity.y || 0;

    this.events.emit(Interaction.EVENT_MOVE, event, true);

    if(this.#isTouching) {
      this.events.emit(Interaction.EVENT_DRAG, event, true);
    }
  }

  #onEnd(event) {
    if (event && event.changedTouches) {
      for (let i = 0; i < event.changedTouches.length; i++) {
        if (event.changedTouches[i].identifier != this.#touchId) {
          return;
        }
      }
    }

    if (!this.#isTouching && !this.unlocked) {
      return;
    }

    let x = this.x;
    let y = this.y;

    if(event.touches || event.changedTouches) {
      if(event.touches.length) {
        x = event.touches[0].clientX;
        y = event.touches[0].clientY;
      } else {
        x = event.changedTouches[0].clientX;
        y = event.changedTouches[0].clientY;
      }
    } else {
      x = event.clientX;
      y = event.clientY;
    }

    this.#isTouching = false;
    this.move.x = 0;
    this.move.y = 0;

    if(Math.max(.001, Render.TIME - (this.#timeMove || Render.TIME)) > 100) {
      this.delta.x = 0;
      this.delta.y = 0;
    }

    if(this.#distance < 20 && Render.TIME - this.#timeDown < 1000 && !event.isLeaveEvent) {
      this.events.emit(Interaction.EVENT_CLICK, event, true);
    }

    this.events.emit(Interaction.EVENT_END, event, true);

    if (event.pointerType && event.pointerType !== 'mouse') {
      this.velocity.x = this.velocity.y = 0;
    }
  }

  #onLeave(event) {
    if(this.ignoreLeave) {
      return;
    }

    this.delta.x = 0;
    this.delta.y = 0;

    event.isLeaveEvent = true;
    this.#onEnd(event);
  }

  #onTick() {
    if(this.#moved++ > 10) {
      this.velocity.x = 0;
      this.velocity.y = 0;
      this.delta.x = 0;
      this.delta.y = 0;
    }
  }
}

//
// Register global events
//
ANTI_core.ready(() => {
  function onStart(evt) {
    _events.start.forEach(callback => callback(evt));
  }

  function onMove(evt) {
    _events.move.forEach(callback => callback(evt));
  }

  function onEnd(evt) {
    _events.end.forEach(callback => callback(evt));
  }

  function onLeave(evt) {
    _events.leave.forEach(callback => callback(evt));
  }

    // await defer();

  if (Interaction.SUPPORTS_POINTER_EVENTS) {
    // If supported, use pointer events API with #setPointerCapture.
    window.addEventListener('pointerdown', onStart, { capture: true, passive: false });
    window.addEventListener('pointermove', onMove, { capture: true, passive: false });
    window.addEventListener('pointerup', onEnd, { capture: true, passive: false });
    window.addEventListener('pointercancel', onEnd, { capture: true, passive: false });
    window.addEventListener('pointerleave', onLeave, { capture: true, passive: false });
    window.addEventListener('pointerout', onLeave, { capture: true, passive: false });
  } else {
    window.addEventListener("touchstart", onStart, { capture: true, passive: false });
    window.addEventListener("mousedown", onStart, { capture: true, passive: false });
    window.addEventListener("touchmove", onMove, { capture: true, passive: false });
    window.addEventListener("mousemove", onMove, { capture: true, passive: false });
    window.addEventListener("touchend", onEnd, { capture: true, passive: false });
    window.addEventListener("mouseup", onEnd, { capture: true, passive: false });
    window.addEventListener("touchcancel", onEnd, { capture: true, passive: false });
    window.addEventListener("mouseleave", onLeave, { capture: true, passive: false });
    window.addEventListener("mouseout", onLeave, { capture: true, passive: false });
  }

    // Always
    window.addEventListener("contextmenu", onEnd, { capture: true, passive: false });
});
