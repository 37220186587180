import Render from './Render';
import { remove as arrayRemove } from './util/Array';

// es6 singleton
let _tweens = [];

function updateTweens(time, dt) {
  for (let i = _tweens.length - 1; i >= 0; i--) {
    let tween = _tweens[i];

    if(tween.update) {
      tween.update(dt);
    } else {
      removeTween(tween);
    }
  }
}

Render.start(updateTweens);

export function addTween(tween) {
  _tweens.push(tween);
}

export function removeTween(tween) {
  arrayRemove(_tweens, tween);
}

// this.clearTween = function(object) {
//     if (object._mathTween && object._mathTween.stop && object._mathTween.stop(),
//     object._mathTweens) {
//         for (var tweens = object._mathTweens, i = 0; i < tweens.length; i++) {
//             var tw = tweens[i];
//             tw && tw.stop && tw.stop()
//         }
//         object._mathTweens = null
//     }
// }


