import Dropdown from './Dropdown';

export default class DropdownSelect extends Dropdown {
  #label;
  #input;
  #options;

  constructor(element) {
    super(element);

    this.#label = this.element.parentElement.querySelector('.DropdownSelect__head');
    this.#input = this.element.querySelector('.DropdownSelect__input');
    this.#options = [...this.element.querySelectorAll('.DropdownSelect__option')];

    this.#options.forEach(el => this.addListener(el));
  }

  addListener(el) {
    el.addEventListener('click', (evt) => this.onOptionClick(evt, el, el.innerText), false);
  }

  onOptionClick(evt, optionEl, label) {
    evt.preventDefault();
    this.#input.value = optionEl.dataset.value;
    this.#label.innerText = label;
    
    this.markOptionAsSelected(optionEl);
    this.close();
  }

  markOptionAsSelected(option) {
    this.#options.forEach(el => el.parentElement.removeAttribute('data-selected'));
    option.parentElement.setAttribute('data-selected', 'true');
  }

}
