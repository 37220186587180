import Overlay from '../ANTICore/Overlay';
import Interaction from '../ANTICore/Interaction';
import Vector2 from '../ANTICore/math/Vector2';
import { clamp, map } from '../ANTICore/util/Math';
import { systemOS } from '../ANTICore/Device';
import tween from '../ANTICore/Tween';

export default class OverlayDrawer extends Overlay {
  #head;
  #body;
  #tween;

  #current = new Vector2();
  #last = new Vector2();
  #min = 0;
  #max = 1;
  #toggleSpeed = 0.25;

  isDragging = false;
  isOpen = false;
  axis = 'y';
  dragEase = 0.3;
  ease = 'easeOutCubic';

  #startListener = this.#onStart.bind(this);
  #dragListener = this.#onDrag.bind(this);
  #endListener = this.#onEnd.bind(this);
  #onTickHandler = this.#onTick.bind(this);

  constructor(element) {
    super(element);

    this.#head = this.element.firstChild;
    this.#current.y = this.#max = this.element.offsetHeight - this.#head.offsetHeight;
    this.#body = this.element.lastChild;
    this.input = new Interaction(this.#head);
    this.input.ignoreLeave = true;

    this.updateContainerPositon();
    this.#addListeners();
    this.startRender(this.#onTickHandler);
  }

  #addListeners() {
    this.events.subscribe(this.input, Interaction.EVENT_START, this.#startListener);
    this.events.subscribe(this.input, Interaction.EVENT_DRAG, this.#dragListener);
    this.events.subscribe(this.input, Interaction.EVENT_END, this.#endListener);
  }

  #onStart(event) {
    if(this.isDragging) {
      return;
    }

    // if(this.controllers.find(el => el === event.target || el.contains(event.target))) {
    //   return;
    // }

    this.#last.copy(this.#current);
    this.clearTweens();

    this.isDragging = true;

    //
    // HACK: For IOS
    //
    if(systemOS === 'ios') {
      document.body.style.overflow = 'hidden';
    }
  }

  #onDrag(event) {
    if(this.isDragging) {
      return;
    }

    event.preventDefault();
    // event.stopPropagation();
  }

  #onEnd() {
    if(!this.isDragging) {
      return;
    }

    this.isDragging = false;

    //
    // HACK: For IOS
    //
    if(systemOS === 'ios') {
      document.body.style.overflow = '';
    }

    if(Math.abs(this.#last[this.axis] - this.#current[this.axis]) > 10) {
      const useVelocity = this.input.velocity[this.axis] > 1.75 && this.isOpen !== this.input.delta[this.axis] < 0;
      this.#toggleSpeed = useVelocity ? 1 / this.input.velocity[this.axis] : 0.25;


      const lastOpenState = this.isOpen;

      if(useVelocity) {
        this.isOpen = !this.isOpen;
      } else {
        this.isOpen = this.elapsed < 0.5;
      }

      if(lastOpenState !== this.isOpen) {
        this.isOpen ? this.open() : this.close();
      } else {
        this.tween(this.isOpen ? this.#min : this.#max, this.#toggleSpeed);
      }
    } else {
      this.#current[this.axis] = this.isOpen ? this.#min : this.#max;
      this.updateContainerPositon();
    }
  }

  tween(goTo, speed = 0.5) {
    this.#tween = tween(
      this.#current,
      { [this.axis]: goTo },
      Math.abs(goTo - this.#current[this.axis]) * speed,
      'easeOutCubic'
    ).onUpdate(() => this.updateContainerPositon());

    return this.#tween;
  }

  #onTick() {
    if(!this.isDragging) {
      return;
    }

    this.#current[this.axis] = clamp(
      this.#current[this.axis]
        + (this.input.move[this.axis]
        - this.#current[this.axis]
        + this.#last[this.axis])
        * this.dragEase,
      this.#min,
      this.#max
    );

    this.updateContainerPositon();
  }

  updateContainerPositon() {
    this.element.style.transform = `translate(${this.#current.x}px, ${this.#current.y}px)`;
  }

  get elapsed() {
    return map(this.#current[this.axis], this.#min, this.#max, 0, 1);
  }

  clearTweens() {
    if(this.#tween && this.#tween.stop) {
      this.#tween = this.#tween.stop();
    }
  }

  onOn() {
    super.onOn();
    this.clearTweens();
    this.tween(this.#min, this.#toggleSpeed).onComplete(() => {
      this.#toggleSpeed = 0.25;
      this.isOpen = true;
    });
  }

  onOff() {
    super.onOff();
    this.clearTweens();
    this.tween(this.#max, this.#toggleSpeed).onComplete(() => {
      this.#toggleSpeed = 0.25;
      this.isOpen = false;
    });
  }

  onUpdateAttributes() {
    if(this.isOn) {
      this.element.classList.add('is-open');
      this.#body.removeAttribute('hidden');
    } else {
      this.element.classList.remove('is-open');
      this.#body.setAttribute('hidden', '');
    }
  }
}
