import Component from '../ANTICore/Component';
import { assignDefined } from '../ANTICore/util/Object';

export default class Marquee extends Component {
  element;
  #container;
  #originalContent;
  #originalContentWidth = 0;

  offsetX = 0;
  #neededClones = null;
  isPlaying = false;

  options = {
    speed: 1,
    reverse: false,
    optional: false,
  };

  #onResizeHandler = this.#resize.bind(this);
  #onTickHandler = this.#onTick.bind(this);

  constructor(element, options = {}) {
    super();

    // Overwrite default options
    Object.assign(this.options, options);

    this.element = element;

    if(!this.element) {
      throw "Marquee needs an element.";
    }

    this.#container = this.element.firstChild;

    if(!this.#container || this.#container.childElementCount !== 1) {
      throw `Marquee should only have one "child" element.`;
    }

    this.#originalContent = this.#container.firstChild;

    this.#addListeners();

    if(!this.options.optional) {
      this.play();
    }
  }

  #addListeners() {
    this.onResize(this.#onResizeHandler);
    document.fonts.ready.then(this.#onResizeHandler);
  }

  play() {
    if(this.isPlaying) {
      return;
    }

    this.isPlaying = true;
    this.startRender(this.#onTickHandler);
  }

  pause() {
    if(!this.isPlaying) {
      return;
    }

    this.isPlaying = false;
    this.stopRender(this.#onTickHandler);
  }

  #onTick(a, b, c, d) {
    if (this.options.reverse ? this.offsetX < 0 : this.offsetX > this.#originalContentWidth * -1) {
      this.offsetX -= this.options.speed * (this.options.reverse ? -1 : 1);
    } else {
      this.offsetX = this.options.reverse ? this.#originalContentWidth * -1 : 0;
    }

    this.#updateContainer();
  }

  #updateContainer() {
    this.#container.style.transform = `translateX(${this.offsetX}px)`;
  }

  #resize(evt) {
    this.#originalContentWidth = this.#originalContent.offsetWidth;
    const isNotLongEnough = this.options.optional && this.#originalContentWidth < this.element.offsetWidth;
    let neededClones;

    if(isNotLongEnough) {
      neededClones = 0;
    } else if(this.#originalContentWidth > this.element.offsetWidth) {
      neededClones = 1;
    } else {
      neededClones = Math.ceil((this.element.offsetWidth - this.#originalContentWidth) / this.#originalContentWidth) + 1;
    }

    // Only clone when needed
    if(neededClones !== this.#neededClones) {
      this.#neededClones = neededClones;
      this.#container.replaceChildren(this.#originalContent, ...Array.from({length: neededClones}, () => this.#originalContent.cloneNode(true)));

      if(isNotLongEnough) {
        this.offsetX = 0;
        this.pause();
        this.#updateContainer();
      } else if (!this.isPlaying) {
        this.play();
      }
    }
  }
}
