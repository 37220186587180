export default class ObjectPool {
  #pool = [];
  #type;
  #number = 10;

  constructor(type, number = 10) {
    this.#type = type;
    this.#number = number;

    if (this.#type) {
      for (let i = 0; i < this.#number; i++) {
        this.#pool.push(new this.#type);
      }
    }
  }

  get() {
    return this.#pool.shift() || (this.#type ? new this.#type : null);
  }

  empty() {
    this.#pool.length = 0;
  }

  put(obj) {
    if(obj) {
      this.#pool.push(obj);
    }
  }

  length() {
    return this.#pool.length;
  }

  destroy() {
    for (let i = this.#pool.length - 1; i >= 0; i--) {
      this.#pool[i].destroy && this.#pool[i].destroy();
    }

    return this.#pool = null;
  }
}
