import * as EasingFunctions from './EasingFunctions';
import { remove as arrayRemove } from './util/Array';
import * as TweenManager from './TweenManager';
import Render from './Render';


class Tween {
  #startTime;
  #currentTime;
  #endValues;
  #startValues;
  #onUpdate;
  #onComplete;


  #elapsed = 0;
  #isPaused = false;

  constructor(object, props, duration, easingFunction = 'linear', delay = 0) {
    if ("number" != typeof duration) {
      throw "Tween requires object, props, duration, and easingFunction.";
    }

    TweenManager.addTween(this);
    this.object = object;
    this.props = props;
    this.duration = duration;
    this.easingFunction = EasingFunctions[easingFunction] || EasingFunctions.linear;
    this.delay = delay;

    this.#startTime = performance.now();
    this.#currentTime = this.#startTime;
    this.#startTime += this.delay;
    this.#endValues = this.props;
    this.#startValues = {};

    for (let prop in this.#endValues) {
      if ('number' == typeof this.object[prop]) {
        this.#startValues[prop] = this.object[prop];
      }
    }
  }

  update(dt) {
    if (this.#isPaused) {
      return;
    }

    this.#currentTime += Render.delta;

    if (this.#currentTime < this.#startTime) {
      return;
    }

    this.#elapsed = (this.#currentTime - this.#startTime) / this.duration;
    this.#elapsed = this.#elapsed > 1 ? 1 : this.#elapsed;

    let delta = this.interpolate(this.#elapsed);

    if(this.#onUpdate) {
      this.#onUpdate(delta);
    }

    if(1 == this.#elapsed) {
      // On complete
      if(this.#onComplete) {
        this.#onComplete();
      }

      // if(_this.completePromise) {
      //   _this.completePromise.resolve()
      // }
      //
      //
      this.#clear();
    }
  }

  interpolate(elapsed) {
    const delta = this.easingFunction(elapsed, this.props.spring, this.props.damping);

    for (let prop in this.#startValues) {
      if ('number' == typeof this.#startValues[prop] && 'number' == typeof this.#endValues[prop]) {
        const start = this.#startValues[prop];
        const end = this.#endValues[prop];

        this.object[prop] = start + (end - start) * delta;
      }

    }
    return delta;
  }

  pause() {
    this.#isPaused = true;
  }

  resume() {
    this.#isPaused = false;
  }

  stop() {
    this.isStopped = true;
    this.#clear();
    return null;
  }

  // this.setEase = function(ease) {
  //     _newEase != ease && (_newEase = ease,
  //     _ease = TweenManager.Interpolation.convertEase(ease),
  //     _easeFunction = "function" == typeof _ease)
  // }

  getValues() {
    return {
      start: this.#startValues,
      end: this.#endValues
    }
  }

  onUpdate(callback) {
    this.#onUpdate = callback;

    return this;
  }

  onComplete(callback) {
    this.#onComplete = callback;

    return this;
  }

  // this.promise = function() {
  //     return _this.completePromise = Promise.create(),
  //     _this.completePromise
  // }

  setElapsed(elapsed) {
    this.#startTime = performance.now();
    this.#currentTime = this.#startTime + this.duration * elapsed;
  }

  #clear() {
    if (!this.object && !this.props) {
      return false;
    }

    TweenManager.removeTween(this);
  }
}

function tween(object, props, duration, easingFunction, delay) {
  return new Tween(object, props, duration, easingFunction, delay);
}

export default tween;
