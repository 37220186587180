import ControlledElement from '../ANTICore/ControlledElement';
import FocusTrap from '../ANTICore/FocusTrap';

export default class DropDown extends ControlledElement {
  //
  // Events
  //
  static EVENT_OPEN = "dropdown_open";
  static EVENT_CLOSE = "dropdown_close";
  static CLICKABLES = ['A'];

  #focusTrap;
  #container;

  constructor(element) {
    super(element);
    this.#container = this.element.parentElement;

    this.#focusTrap = new FocusTrap(this.element);
    this.onBodyClickHandler = this.onBodyClick.bind(this);
  }

  onBodyClick(evt) {
    if (this.#container.parentElement.contains(evt.target) && !DropDown.CLICKABLES.includes(evt.target.tagName)) {
      return;
    }

    this.close();
  }

  open() {
    this.on();
  }

  close() {
    this.off();
  }

  onOn() {
    this.element.removeAttribute('hidden');
    document.addEventListener('click', this.onBodyClickHandler, false);

    this.events.emit(DropDown.EVENT_OPEN);
    this.#focusTrap.trapFocus();
  }

  onOff() {
    this.element.setAttribute('hidden', '');
    document.removeEventListener('click', this.onBodyClickHandler, false);

    this.events.emit(DropDown.EVENT_CLOSE);
    this.#focusTrap.releaseFocus();
  }
}
