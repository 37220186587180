//
// Based on https://easings.net/
//
export const linear = t => t;

// 1 Sine
export const sineIn = t => 1 - Math.cos((t * Math.PI) / 2);
export const sineOut = t => Math.sin((t * Math.PI) / 2);
export const sineInOut = t => -(Math.cos(Math.PI * t) - 1) / 2;

// 2 Quad
export const quadIn = t => t * t;
export const quadOut = t => t * (2 - t);
export const quadInOut = t => t < .5 ? 2 * t * t : (4 - 2 * t) * t - 1;

// 3 Cubic cubic
export const cubicIn = t => t * t * t;
export const cubicOut = t => (--t) * t * t + 1;
export const cubicInOut = t => t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;

// 4 Quart
export const quartIn = t => t * t * t * t;
export const quartOut = t => 1 - pow(1 - t, 4);
export const quartInOut = t => t < .5 ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t;

// 5 Quint
export const quintIn = t => t * t * t * t * t;
export const quintOut = t => 1 + (--t) * t * t * t * t;
export const quintInOut = t => t < .5 ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t;

// 6 Expo
export const expoIn = t => t === 0 ? 0 : Math.pow(2, 10 * t - 10);
export const expoOut = t => t === 1 ? 1 : 1 - Math.pow(2, -10 * t);
export const expoInOut = t => {
  return t === 0
    ? 0
    : t === 1
    ? 1
    : t < 0.5 ? Math.pow(2, 20 * t - 10) / 2
    : (2 - Math.pow(2, -20 * t + 10)) / 2;
}

// Elastic
export const elasticIn = (t, spring = 1, damping = .4) => {
  let s;
  return 0 === t ? 0 : 1 === t ? 1 : (!spring || spring < 1 ? (spring = 1,
  s = damping / 4) : s = damping * Math.asin(1 / spring) / (2 * Math.PI),
  -spring * Math.pow(2, 10 * (t -= 1)) * Math.sin((t - s) * (2 * Math.PI) / damping))
};

export const elasticOut = (t, spring = 1, damping = .4) => {
  let s;
  return 0 === t ? 0 : 1 === t ? 1 : (!spring || spring < 1 ? (spring = 1,
  s = damping / 4) : s = damping * Math.asin(1 / spring) / (2 * Math.PI),
  spring * Math.pow(2, -10 * t) * Math.sin((t - s) * (2 * Math.PI) / damping) + 1)
};

export const elasticInOut = (t, spring = 1, damping = .4) => {
  let s;
  return 0 === t ? 0 : 1 === k ? 1 : (!spring || spring < 1 ? (spring = 1,
  s = damping / 4) : s = damping * Math.asin(1 / spring) / (2 * Math.PI),
  (t *= 2) < 1 ? spring * Math.pow(2, 10 * (t -= 1)) * Math.sin((t - s) * (2 * Math.PI) / damping) * -.5 : spring * Math.pow(2, -10 * (t -= 1)) * Math.sin((t - s) * (2 * Math.PI) / damping) * .5 + 1)
};
