export function includes(source, str) {
  if (!Array.isArray(str)) {
    return !!~source.indexOf(str);
  }

  for (let i = str.length - 1; i >= 0; i--) {
    if (!!~source.indexOf(str[i])) {
      return true;
    }
  }

  return false;
};

export function strpos(source, str) {
  console.warn('strpos deprecated: use .includes()');

  return source.includes(str);
};

export function clip(source, num, end) {
  return source.length > num ? source.slice(0, num) + end : source;
};


export function capitalize(source) {
  return source.charAt(0).toUpperCase() + source.slice(1);
};


export function replaceAll(source, find, replace) {
  return source.split(find).join(replace);
};
