export function registerContainerLink(el, url) {
  if(!(el instanceof Element)) {
    throw new Error('el needs to be a DOM element.');
  }

  if(!url) {
    throw new Error('url needs to be set.');
  }

  const clickableElements = [...el.querySelectorAll('a, button')];
  const mainLink = clickableElements.find(el => el.href === url);

  if(!mainLink) {
    return;
  }

  clickableElements.forEach((el) =>
    el.addEventListener('click', evt => evt.stopPropagation())
  );

  function handleClick(event) {
    const noTextSelected = !window.getSelection().toString();

    if (noTextSelected) {
      mainLink.click();
    }
  }

  el.addEventListener('click', handleClick);
}
