import Component from '../ANTICore/Component';

export default class TextSplit extends Component {
  static EVENT_UPDATE = "update";

  #resize = this.resize.bind(this);

  #textContent = '';
  words = [];
  lines = [];

  get lineCount() {
    return this.lines.length;
  }

  get wordCount() {
    return this.words.length;
  }

  constructor(element) {
    super();

    this.element = element;
    this.element.normalize();
    this.#textContent = this.element.textContent;
    this.words = this.#textContent.split(' ').map( word => {
      const node = document.createElement('span');
      node.classList.add('word');
      node.textContent = word;

      return node;
    });

    this.#addListeners();
  }

  #split() {
    const wordsWrapper = document.createDocumentFragment();

    this.words.forEach((el, index) => {
      wordsWrapper.appendChild(el);

      if (index < this.words.length - 1) {
        // Add a space after the word.
        wordsWrapper.appendChild(document.createTextNode(' '));
      }
    });

    // Replace the original HTML content of the element with the `splitText`
    this.element.replaceChildren(wordsWrapper);

    // Calculate lines
    const lines = {};

    this.words.forEach(el => {
      const val = Math.round(el['offsetTop']);
      (lines[val] || (lines[val] = [])).push(el);
    });

    const linesWrapper = document.createDocumentFragment();
    const linesCount = Object.keys(lines).length;

    this.lines = Object.keys(lines).map((key, lineIndex) => {
      const line = lines[key];
      const wordCount = line.length;
      const isLastLine = lineIndex === linesCount - 1;

      const node = document.createElement('span');
      node.classList.add('line');

      line.forEach( (word, wordIndex) => {
        const isLastWord = wordIndex === wordCount - 1;
        node.appendChild(word);

        if (!(isLastWord && isLastLine)) {
          // Add a space after the word.
          node.appendChild(document.createTextNode(' '));
        }
      });

      linesWrapper.appendChild(node);

      return node;
    });

    this.element.replaceChildren(linesWrapper);

    this.events.emit(TextSplit.EVENT_UPDATE, {
      words: this.words,
      wordCount: this.wordCount,
      lines: this.lines,
      lineCount: this.lineCount
    });
  }

  #addListeners() {
    this.onResize(this.#resize);
  }

  resize(evt) {
    this.#split();
  }
}
